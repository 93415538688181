<template>
	<div class="detail-wrap">
		<div class="banner-wrap">
			<el-carousel height="300px" arrow="hover" v-loading="loading" v-if="adList.length>0">
				<el-carousel-item v-for="item in adList" :key="item.adv_id">
					<el-image :src="$img(item.adv_image)" fit="cover"
							  @click="$router.pushToTab(item.adv_url.url)" />
				</el-carousel-item>
			</el-carousel>
			<img v-else src="@/assets/images/nationwide/news_banner.jpg"/>
		</div>
		<el-breadcrumb separator="/" class="crumbs">
			<el-breadcrumb-item :to="{ path: '/' }" class="path-home">
				<i class="el-icon-s-home"></i>
				首页
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/promotion/newsInformation' }">新闻资讯</el-breadcrumb-item>
			<el-breadcrumb-item class="path-help">详情</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="help-detail" v-loading="loading">
			<div class="title">{{ detail.title }}</div>
			<div class="info">
				<div class="time">{{ detail.create_time}}</div>
			</div>
			<div class="content" v-html="detail.content"></div>
		</div>
	</div>
</template>

<script>
	import {adList} from "@/api/website";
	import { articleDetail } from '@/api/cms/article';
	export default {
		name: 'help_detail',
		components: {},
		data: () => {
			return {
				id:0,
				adList:[],
				detail: [],
				loading: true
			};
		},
		created() {
			this.getAdList()
			this.id = this.$route.path.replace('/promotion/newsInformation/detail-', '')
			this.getDetail()
		},
		watch: {
			$route(curr) {
				this.id = curr.params.pathMatch;
				this.getDetail();
			}
		},
		methods: {
			getDetail() {
				articleDetail({
					id: this.id
				})
						.then(res => {
							if (res.code == 0) {
								if (res.data) {
									this.loading = false;
									this.detail = res.data;
								} else {
									this.$router.push({ path: '/promotion/newsInformation' });
								}
							}
						})
						.catch(err => {
							this.loading = false;
							this.$message.error(err.message);
						});
			},
			getAdList() {
				adList({
					keyword: "NS_PC_NEWS"
				})
				.then(res => {
					this.adList = res.data.adv_list;
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url)
							this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
					}
					this.loading = false
				})
				.catch(err => {
					this.loading = false
				})
			},
		}
	};
</script>
<style lang="scss">
	.el-carousel__arrow--left{left: 150px}
	.el-carousel__arrow--right{right: 150px}
	.banner-wrap{
		width:100%;
		height:300px;
		margin:0;
		padding:0;
		position: relative;
		img{
			height:300px;
			width: 100%;
		}
	}
	.crumbs{
		width: 1210px;
		margin: 30px auto;
		position: relative;
		padding: 0;
	}
	.help-detail {
		background-color: #ffffff;
		padding: 40px;
		border-radius: 8px;
		margin:0 auto;
		width: 1130px;
		.title {
			text-align: center;
			font-size: 18px;
			margin: 10px 0;
			padding-bottom: 35px;
			color:#484848;
			font-size:28px;
			border-bottom: 1px solid #D8D8D8;
		}
		.info {
			margin:0 auto;
			text-align: center;
			.time {
				text-align: center;
				color: #959595;
				margin-bottom: 17px;
			}
		}
		.content {
			padding-top: 10px;
			margin: 0 65px;
			color:#301D1D;
		}
	}
</style>
